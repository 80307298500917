import { Icon } from 'components';
import { Logo } from 'components/Logo';
import { t } from 'i18n';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'theme';
import { UserContext } from 'UserContext';

export const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useContext(UserContext);

  return (
    <s.div.container>
      <s.div.menu>
        <s.Logo width={110} height={28} />
        <s.NavLink exact={true} to="/">
          {t('Home')}
        </s.NavLink>
        <s.NavLink to="/all">{t('All Glossaries')}</s.NavLink>
        {user.isAdmin && <s.NavLink to="/users">{t('users')}</s.NavLink>}
        <s.NavLink to="/settings">{t('settings')}</s.NavLink>
        <s.hr />
        <s.NavLink to="/logout">{t('logOut')}</s.NavLink>
      </s.div.menu>
      <s.div.contents>{children}</s.div.contents>
    </s.div.container>
  );
};

const s = {
  Logo: styled(Logo)`
    padding: 0px 0px 3px 4px;
  `,
  img: styled.img`
    display: inline-block;
    margin-right: 4px;
    width: 20px;
  `,
  Icon: styled(Icon)`
    display: inline-block;
    margin-right: 6px;
  `,
  hr: styled.hr`
    border: none;
    border-bottom: 1px ${colors.grey2} solid;
  `,
  div: {
    container: styled.div`
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    `,
    header: styled.div`
      display: flex;
      height: 40px;
      width: 100%;
    `,
    menu: styled.div`
      background-color: ${colors.grey1};
      padding: 5px;
      width: 140px;

      @media print {
        display: none;
      }
    `,
    contents: styled.div`
      display: flex;
      flex: 1;
      padding: 0px;
      height: 100%;
    `
  },
  NavLink: styled(NavLink)`
    border-radius: 3px;
    color: ${colors.grey5};
    display: block;
    font-size: 13px;
    text-decoration: none;
    line-height: 15px;
    margin-bottom: 1px;
    padding: 5px 6px;

    &:hover {
      background-color: ${colors.blue5};
    }

    &.active {
      background-color: ${colors.grey5};
      color: ${colors.white};
    }
  `
};
