import React from 'react';
import styled from 'styled-components';

export const Field: React.FC<{ children: React.ReactNode }> = ({ children }) => <s.field>{children}</s.field>;

const s = {
  field: styled.div`
    margin: 0 0 24px;
  `
};
