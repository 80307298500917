import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const Submit: React.FC<{ text: string }> = ({ text }) => <s.input type="submit" value={text} />;

const s = {
  input: styled.input`
    background-color: ${colors.blue2};
    border: none;
    color: ${colors.white};
    display: block;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    margin: 0 4px 8px;
    padding: 0 32px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background-color: ${colors.blue3};
      cursor: pointer;
    }
    &:focus {
      background-color: ${colors.blue3};
      box-shadow: 0 0 8px 4px ${colors.blue2};
    }
  `
};
