/* eslint-disable react/display-name */
import { GridCellValue, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Status } from 'components/terms/Status';
import dayjs from 'dayjs';
import React from 'react';

export const getAllColumns = (): any => {
  const fields = ['Glossary', '日本語', '英語'].filter(field => field && field !== '');

  const columns: GridColDef[] = fields.map((field, index) => ({
    headerName: field,
    field: index === 0 ? 'project' : `field${index}`,
    flex: 1
  }));

  const status: GridColDef = {
    field: 'status',
    headerName: ' ',
    resizable: false,
    width: 40,
    renderCell: (params: GridValueFormatterParams) => <Status color={String(params.value) || ''} />
  };

  const dates: GridColDef[] = [
    {
      headerName: 'Created',
      field: 'createdAt',
      width: 140,
      valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
        dayjs(String(params.value)).format('YY/MM/DD HH:mm')
    },
    {
      headerName: 'Updated',
      field: 'updatedAt',
      width: 140,
      valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
        dayjs(String(params.value)).format('YY/MM/DD HH:mm')
    }
  ];

  return [status, ...columns, ...dates];
};
