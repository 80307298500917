import { GridSelectionModel } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { Content, Grid, Layout, Sidebar } from 'components';
import { TermForm } from 'components/terms/TermForm';
import { getAllColumns } from 'helpers/allColumns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Project as ProjectModel } from 'types';
import { UserContext } from 'UserContext';

export const All: React.FC = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<ProjectModel | undefined>();
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [term, setTerm] = useState<any>();
  const [terms, setTerms] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [termFormOpen, setTermFormOpen] = useState(false);

  const fields = ['project', '日本語', '英語'].filter(field => field && field !== '') as string[];

  const loadTerms = useCallback((): void => {
    setLoading(true);
    axios
      .get(`/api/terms`)
      .then(response => {
        setLoading(false);
        const { terms } = response.data;

        setTerms(
          terms.map(
            ([
              id,
              projectId,
              project,
              field1,
              field2,
              field3,
              field4,
              createdAt,
              createdBy,
              updatedAt,
              updatedBy,
              status
            ]: any) => {
              return {
                id,
                projectId,
                project,
                field1,
                field2,
                field3,
                field4,
                createdAt,
                createdBy,
                updatedAt,
                updatedBy,
                status
              };
            }
          )
        );
      })
      .catch(() => {
        setRedirect('/logout');
      });

    axios.get(`/api/projects`).then(response => setProjects(response.data));
  }, []);

  const editTerm = (currentTermId: any): void => {
    const currentTerm: any = terms.find(({ id }) => id === currentTermId);

    if (user && currentTerm) {
      setTermFormOpen(true);
      setTerm(currentTerm);

      const currentProject = projects.find(({ id }) => id === currentTerm.projectId);
      if (currentProject) {
        setProject(currentProject);
      }
    }
  };

  useEffect(() => {
    loadTerms();
  }, [loadTerms]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Layout>
      <Content>
        <s.div>
          <s.h1>All Glossaries</s.h1>
        </s.div>
        <Grid
          name={'All'}
          columns={getAllColumns()}
          rows={terms}
          loading={loading}
          searchFields={fields.map((_, index: number) => `field${index + 1}`) || []}
          canDownload={user.canEdit}
          onRowSelected={(selectionModel: GridSelectionModel): void => {
            editTerm(selectionModel[0]);
          }}
        />
      </Content>
      <Sidebar visible={termFormOpen}>
        <TermForm project={project} term={term} setVisible={setTermFormOpen} onRefresh={(): void => loadTerms()} />
      </Sidebar>
    </Layout>
  );
};

const s = {
  h1: styled.h1`
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    margin: 0px;
    padding: 5px 10px 5px 0px;
    text-transform: uppercase;
  `,
  div: styled.div`
    display: flex;
    height: 40px;
    width: 100%;
  `
};
