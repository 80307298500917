import axios from 'axios';
import { Button, Buttons, Grid, Heading } from 'components';
import React, { FC, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import styled from 'styled-components';
import { colors } from 'theme';
import { Project } from 'types';

export const UploadForm: FC<{ project: Project; onCancel(): void; onComplete(): void }> = ({
  project,
  onCancel,
  onComplete
}) => {
  const { CSVReader } = useCSVReader();
  const [terms, setTerms] = useState<string[]>([]);

  const handleUpload = (results: any): void => {
    setTerms(results.data);
  };

  const handleSubmit = (): void => {
    axios.post('/api/terms_upload/', { projectId: project.id, terms }).then(() => {
      onComplete();
    });
  };

  return (
    <CSVReader onUploadAccepted={handleUpload}>
      {({ acceptedFile, getRootProps }: any): JSX.Element => (
        <s.div.container>
          <Heading>Upload from CSV</Heading>
          {!acceptedFile && <s.button {...getRootProps()}>Select CSV File</s.button>}
          {acceptedFile && terms.length > 0 && (
            <s.div.grid>
              <Heading>{acceptedFile.name}</Heading>
              Preview:
              <Grid
                name=""
                columns={[
                  { headerName: project.field1, field: 'field1', flex: 1 },
                  { headerName: project.field2, field: 'field2', flex: 1 },
                  { headerName: project.field3, field: 'field3', flex: 1 },
                  { headerName: project.field4, field: 'field4', flex: 1 }
                ]}
                rows={terms.map((term, index) => ({
                  id: index,
                  status: 'GREEN',
                  field1: term[0],
                  field2: term[1],
                  field3: term[2],
                  field4: term[3]
                }))}
                loading={false}
                canSearch={false}
                canDownload={false}
                searchFields={[]}
              />
            </s.div.grid>
          )}
          {acceptedFile && (
            <Buttons>
              <Button text="Cancel" onClick={onCancel} type={Button.types.default} />
              <Button text="Upload" onClick={handleSubmit} type={Button.types.submit} />
            </Buttons>
          )}
        </s.div.container>
      )}
    </CSVReader>
  );
};

const s = {
  div: {
    container: styled.div`
      width: 800px;
    `,
    grid: styled.div`
      height: 500px;
    `
  },
  button: styled.button`
    background-color: ${colors.blue2};
    border: none;
    border-radius: 3px;
    color: ${colors.white};
    display: block;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    line-height: 12px;
    margin: 0px 2px;
    opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
    padding: 0 12px;
    text-align: center;
    text-transform: uppercase;
    flex: 1;

    &:hover {
      background-color: ${colors.blue3};
      cursor: pointer;
    }
    &:focus {
      background-color: ${colors.blue3};
      box-shadow: 0 0 8px 4px ${colors.blue2};
    }
  `
};
