import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const RadioButton = React.forwardRef(
  (
    {
      name,
      label,
      value,
      defaultChecked = false
    }: { name: string; label: string; value: string; defaultChecked?: boolean },
    ref?: React.Ref<HTMLInputElement>
  ) => {
    return (
      <s.label htmlFor={`${name}-${value}`}>
        <s.input
          ref={ref}
          id={`${name}-${value}`}
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          type={'radio'}
        />
        <s.span>{label}</s.span>
      </s.label>
    );
  }
);

RadioButton.displayName = 'Checkbox';

const s = {
  input: styled.input`
    display: inline-block;
    margin-right: 8px;
  `,
  span: styled.span`
    display: inline-block;
  `,
  label: styled.label`
    background-color: ${colors.grey1};
    display: block;
    line-height: 32px;
    margin-bottom: 2px;
    padding: 0 8px;

    &:hover {
      background-color: ${colors.grey2};
      cursor: pointer;
    }
  `
};
