import axios from 'axios';
import { Button, Buttons, Field, Fields, Form, Heading, Input, Label, Layout } from 'components';
import { useNotification } from 'hooks/useNotification';
import { t } from 'i18n';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { colors } from 'theme';
import { UserContext } from 'UserContext';

export const Settings: React.FC = () => {
  const { user } = useContext(UserContext);
  const { register, handleSubmit } = useForm();
  const { success } = useNotification();

  const update = ({ password }: any): void => {
    const updatedUser = {
      id: user.id,
      password
    };

    axios.put(`/api/settings/`, updatedUser).then(() => {
      success(t('settingsUpdated'));
    });
  };

  return (
    <Layout>
      <s.Form>
        <Heading>{t('settings')}</Heading>
        <Fields>
          {user.isAdmin && <s.div>{t('administratorAccount')}</s.div>}
          <Field>
            <Label htmlFor="username">{t('username')}</Label>
            <Input name="username" defaultValue={user.username} disabled={true} />
          </Field>
          <Field>
            <Label htmlFor="password">{t('password')}</Label>
            <Input ref={register} name="password" type="password" />
          </Field>
        </Fields>
        <Buttons>
          <Button text={t('update')} type={Button.types.submit} onClick={handleSubmit(update)} />
        </Buttons>
      </s.Form>
    </Layout>
  );
};

const s = {
  div: styled.div`
    color: ${colors.green};
    background-color: ${colors.green2};
    margin-bottom: 24px;
    padding: 8px;
  `,
  Form: styled(Form)`
    border: none;
  `
};
