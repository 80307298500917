import React from 'react';
import styled from 'styled-components';
import { getStatusColor } from 'theme';

export const Status: React.FC<{ color: string }> = ({ color }) => <s.div color={color} />;

const s = {
  div: styled.div<{ color: string }>`
    background-color: ${({ color }): string => getStatusColor(color)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
  `
};
