import React from 'react';
import styled from 'styled-components';

export const Content: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <s.div.container>{children}</s.div.container>;
};

const s = {
  div: {
    container: styled.div`
      flex: 1;
      padding: 0px 10px 10px;
    `
  }
};
