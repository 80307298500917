import { NotificationsContext } from 'NotificationsContext';
import { useContext } from 'react';

export function useNotification(): any {
  const { notification } = useContext(NotificationsContext);

  const success = (message: string): void => {
    notification.current.addNotification({
      message: message,
      level: 'success'
    });
  };

  return { notification, success };
}
