import React from 'react';
import styled from 'styled-components';

export const Heading: React.FC<{ children: React.ReactNode }> = ({ children }) => <s.h1>{children}</s.h1>;

const s = {
  h1: styled.h1`
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    margin: 0px;
    padding: 5px 10px 5px 0px;
    text-transform: uppercase;
  `
};
