import { useState } from 'react';

export const useUser = (): any => {
  const localUser = localStorage.getItem('user');
  const initialState = localUser ? JSON.parse(localUser) : null;

  const [user, setUser] = useState(initialState);

  const saveUser = ({ id, username, projects, role }: any): void => {
    const userModel = {
      id,
      username,
      projects,
      role,
      isAdmin: role === 'ADMIN',
      canEdit: ['ADMIN', 'EDITOR'].includes(role)
    };
    localStorage.setItem('user', JSON.stringify(userModel));
    setUser(userModel);
  };

  const deleteUser = (): void => {
    localStorage.clear();
    setUser(null);
  };

  return { user, saveUser, deleteUser };
};
