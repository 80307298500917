import axios from 'axios';
import { Button, Buttons, CopyButton, Field, Fields, Form, Heading, Icon, Label, Modal, Textarea } from 'components';
import { TermRevisions } from 'components/terms/TermRevisions';
import { useNotification } from 'hooks/useNotification';
import { t } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { colors, getStatusColor } from 'theme';
import { UserContext } from 'UserContext';

export const TermForm: React.FC<{
  onRefresh?(): void;
  setVisible(status: boolean): any;
  project: any;
  term: any;
}> = ({ onRefresh, setVisible, project, term }) => {
  const [warningOpen, setWarningOpen] = useState(false);
  const [revisionsOpen, setRevisionsOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const { success } = useNotification();
  const { user } = useContext(UserContext);

  const closeForm = (): void => {
    setVisible(false);
  };

  const openWarningModal = (): void => {
    setWarningOpen(true);
  };
  const closeWarningModal = (): void => {
    setWarningOpen(false);
  };

  const openRevisions = (): void => {
    setRevisionsOpen(true);
  };

  const create = (fields: any): void => {
    const currentTerm = {
      projectId: project.id,
      ...fields
    };

    axios.post(`/api/terms/`, currentTerm).then(() => {
      closeForm();
      success(t('termCreated'));
      if (onRefresh) {
        onRefresh();
      }
    });
  };
  const update = (fields: any): void => {
    const currentTerm = {
      id: term.id,
      projectId: project.id,
      ...fields
    };

    axios.put(`/api/terms/`, currentTerm).then(() => {
      closeForm();
      success(t('termUpdated'));
      if (onRefresh) {
        onRefresh();
      }
    });
  };
  const destroy = (): void => {
    closeWarningModal();

    axios.delete(`/api/terms/`, { data: { id: term.id } }).then(() => {
      closeForm();
      success(t('termDeleted'));
      if (onRefresh) {
        onRefresh();
      }
    });
  };

  useEffect(() => {
    reset();
  }, [reset, term]);

  if (!project) {
    return null;
  }

  return (
    <Form>
      <s.Icon.Close icon={Icon.icons.x} onClick={closeForm} />
      <Heading>{term?.id ? t('editTerm') : t('newTerm')}</Heading>
      <Fields>
        {[project.field1, project.field2, project.field3, project.field4]
          .filter(field => field !== '')
          .map((field: string, index: number) => (
            <Field key={index}>
              <Label>
                {field}
                <CopyButton text={(term && term[`field${index + 1}`]) ?? ''} />
              </Label>
              <Textarea
                name={`field${index + 1}`}
                ref={register}
                defaultValue={(term && term[`field${index + 1}`]) ?? ''}
              />
            </Field>
          ))}
        <Field>
          <Label htmlFor={`status`}>Status</Label>
          {['RED', 'YELLOW', 'GREEN'].map(value => (
            <s.label key={value}>
              <s.input
                defaultChecked={term?.status === value}
                id={`status-${value}`}
                name={'status'}
                ref={register}
                type={'radio'}
                value={value}
              />
              <s.div status={value} />
            </s.label>
          ))}
        </Field>
      </Fields>
      {term && (
        <s.Icon.Revisions icon={Icon.icons.clock} onClick={openRevisions}>
          {t('history')}
        </s.Icon.Revisions>
      )}
      {user.canEdit && (
        <Buttons>
          <Button text={t('cancel')} onClick={closeForm} />
          {term?.id ? (
            <>
              <Button text={t('delete')} type={Button.types.destroy} onClick={openWarningModal} />
              <Button text={t('update')} type={Button.types.submit} onClick={handleSubmit(update)} />
            </>
          ) : (
            <Button text={t('create')} type={Button.types.submit} onClick={handleSubmit(create)} />
          )}
        </Buttons>
      )}
      <Modal visible={warningOpen} setVisible={setWarningOpen}>
        <Heading>{t('areYouSure')}</Heading>
        <Buttons>
          <Button text={t('cancel')} onClick={closeWarningModal} />
          <Button text={t('delete')} type={Button.types.destroy} onClick={destroy} />
        </Buttons>
      </Modal>
      <TermRevisions project={project} term={term} visible={revisionsOpen} setVisible={setRevisionsOpen} />
    </Form>
  );
};

const s = {
  label: styled.label`
    display: inline-block;
    margin-right: 4px;

    input:checked + div {
      border-color: ${colors.grey3};
    }
  `,
  div: styled.div<{ status: string }>`
    border-color: ${colors.transparent};
    border-style: solid;
    border-width: 2px;
    height: 24px;
    border-radius: 50%;
    width: 24px;

    &:hover {
      cursor: pointer;
      border-color: ${colors.grey2};
    }

    &::after {
      border-radius: 50%;
      background-color: ${({ status }): string => getStatusColor(status)};
      color: ${colors.transparent};
      content: '';
      display: block;
      line-height: 16px;
      height: 16px;
      margin: 2px;
      width: 16px;
    }
  `,

  input: styled.input`
    display: none;
  `,
  Icon: {
    Close: styled(Icon)`
      position: absolute;
      top: 0px;
      right: 0px;

      &::after {
        font-size: 22px;
        line-height: 40px;
        height: 40px;
        width: 40px;
      }
    `,
    Revisions: styled(Icon)`
      font-size: 0.875rem;
      margin-bottom: 20px;
      padding-left: 20px;
      position: relative;
      line-height: 20px;

      &::after {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    `
  }
};
