import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

enum Types {
  default = 'default',
  destroy = 'destroy',
  submit = 'submit'
}

export const Button: React.FC<{ text: string; type?: Types; onClick?(): void; disabled?: boolean }> & {
  types: typeof Types;
} = ({ text, type = Types.default, onClick, disabled = false }) => {
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <s.button onClick={clickHandler} buttonType={type} disabled={disabled}>
      {text}
    </s.button>
  );
};

Button.types = Types;

const getBackgroundColor = (type: Types): string => {
  switch (type) {
    case Types.destroy:
      return colors.red;
    case Types.submit:
      return colors.blue2;

    default:
      return colors.grey2;
  }
};

const s = {
  button: styled.button<{ buttonType: Types; disabled: boolean }>`
    background-color: ${({ buttonType }): string => getBackgroundColor(buttonType)};
    border: none;
    border-radius: 3px;
    color: ${colors.white};
    display: block;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    line-height: 12px;
    margin: 0px 2px;
    opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
    padding: 0 12px;
    text-align: center;
    text-transform: uppercase;
    flex: 1;

    &:hover {
      background-color: ${colors.blue3};
      cursor: pointer;
    }
    &:focus {
      background-color: ${colors.blue3};
      box-shadow: 0 0 8px 4px ${colors.blue2};
    }
  `
};
