import { Icon } from 'components';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const CopyButton: React.FC<{ text: string }> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = (): void => {
    copy(text);
    setCopied(true);
  };

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  return copied ? (
    <s.Icon.Check icon={Icon.icons.check}>
      <s.span>copied</s.span>
    </s.Icon.Check>
  ) : (
    <s.Icon.Copy icon={Icon.icons.clipboard} onClick={handleClick}>
      <s.span>copy text</s.span>
    </s.Icon.Copy>
  );
};

const s = {
  span: styled.span`
    background-color: ${colors.grey5};
    border-radius: 4px;
    color: ${colors.white};
    display: block;
    left: 20px;
    line-height: 20px;
    opacity: 0;
    padding: 0px 6px;
    position: absolute;
    top: -1px;
    transition: opacity 0.3s;
    white-space: nowrap;
  `,
  Icon: {
    Copy: styled(Icon)`
      color: ${colors.grey2};
      display: inline-block;
      margin-left: 6px;
      position: relative;

      &:hover {
        color: ${colors.grey5};
        span {
          opacity: 1;
          transition: opacity 0s;
        }
      }

      &::after {
        font-size: 12px;
      }
    `,
    Check: styled(Icon)`
      display: inline-block;
      margin-left: 6px;
      position: relative;

      span {
        top: -3px;
      }

      &:hover {
        span {
          opacity: 1;
          transition: opacity 0s;
        }
      }

      &::after {
        background-color: ${colors.green};
        border-radius: 50%;
        color: ${colors.white};
        cursor: default;
        font-size: 9px;
        line-height: 15px;
        font-weight: 700;
      }
    `
  }
};
