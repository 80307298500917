import axios from 'axios';
import { Modal } from 'components';
import { Heading } from 'components/Heading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { diffWords } from 'diff';
import { t } from 'i18n';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

dayjs.extend(relativeTime);

export const TermRevisions: React.FC<{
  project: any;
  term: any;
  visible: boolean;
  setVisible(status: boolean): void;
}> = ({ project, term, visible, setVisible }) => {
  const [terms, setTerms] = useState<any>([]);
  const fields = [project?.field1, project?.field2, project?.field3, project?.field4].filter(
    field => field && field !== ''
  );

  const loadTerms = useCallback((term: any): void => {
    axios.get(`/api/term-revisions/${term.id}`).then(response => {
      const termRevisions = response.data.termRevisions.map((termRevision: any): any => {
        const [id, field1, field2, field3, field4, createdAt, createdBy] = termRevision;
        return {
          id,
          field1,
          field2,
          field3,
          field4,
          createdAt,
          createdBy
        };
      });

      setTerms([term, ...termRevisions]);
    });
  }, []);

  const getType = (added?: boolean, removed?: boolean): string => {
    return added && added === true ? 'add' : removed && removed === true ? 'remove' : 'none';
  };

  const getDiff = (previousTerm: string, currentTerm: string): React.ReactNode => {
    return diffWords(previousTerm, currentTerm).map(({ added, removed, value }) => (
      <s.span key={value} type={getType(added, removed)}>
        {value}
      </s.span>
    ));
  };

  useEffect(() => {
    if (visible && term) {
      loadTerms(term);
    }
  }, [loadTerms, term, visible]);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>{t('history')}</Heading>

      <s.table>
        <tr>
          {fields.map((field: string) => (
            <s.th key={`header-${field}`}>{field}</s.th>
          ))}
          <s.th></s.th>
        </tr>
        {terms.map((term: any, index: number): JSX.Element | null =>
          index === 0 ? null : (
            <tr key={`term-${term.id}`}>
              {fields.map((_: string, fieldIndex: number) => (
                <s.td.Field key={`field-${fieldIndex}`}>
                  {getDiff(term[`field${fieldIndex + 1}`] ?? '', terms[index - 1][`field${fieldIndex + 1}`] ?? '')}
                </s.td.Field>
              ))}
              <s.td.CreatedBy>
                {term.createdBy}
                <br />
                <s.em>{dayjs(term.createdAt).fromNow()}</s.em>
              </s.td.CreatedBy>
            </tr>
          )
        )}
        {terms[0] && (
          <tr>
            <s.td.Field colSpan={fields.length}>&nbsp;</s.td.Field>
            <s.td.CreatedBy>
              {terms[0].createdBy}
              <br />
              <s.em>{dayjs(terms[0].createdAt).fromNow()}</s.em>
            </s.td.CreatedBy>
          </tr>
        )}
      </s.table>
    </Modal>
  );
};

const s = {
  em: styled.em`
    color: ${colors.grey3};
  `,
  table: styled.table`
    border-collapse: collapse;
    border-spacing: 0px;
    table-layout: fixed;
    max-width: 1000px;
    width: 100%;
    td {
      font-size: 0.875rem;
      border-color: ${colors.grey2};
      border-style: solid;
      border-width: 1px 0px 0px;
      padding: 4px 2px;
      vertical-align: top;
    }
  `,
  th: styled.th`
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
  `,
  td: {
    Field: styled.td``,
    CreatedBy: styled.td`
      width: 100px;
    `
  },
  span: styled.span<{ type: string }>`
    background-color: ${({ type }): string =>
      type === 'add' ? colors.green3 : type === 'remove' ? colors.red3 : 'transparent'};
    ${({ type }): string => (type === 'remove' ? 'text-decoration: line-through' : '')};
  `
};
