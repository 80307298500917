import { GridColDef, GridSelectionModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { Content, CreateButton, Grid, Icon, Layout, Sidebar } from 'components';
import { UserForm } from 'components/users/UserForm';
import { t } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from 'UserContext';

export const Users: React.FC = () => {
  const [userFormOpen, setUserFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [users, setUsers] = useState<any>([]);
  const [redirect, setRedirect] = useState('');
  const { user: currentUser } = useContext(UserContext);

  const loadUsers = (): void => {
    setLoading(true);

    axios
      .get(`/api/users`)
      .then(response => {
        setLoading(false);

        setUsers(response.data);
      })
      .catch(() => setRedirect('/logout'));
  };

  const createUser = (): void => {
    setUserFormOpen(true);
    setUser(undefined);
  };

  const editUser = (currentUserId: any): void => {
    const currentUser = users.find(({ id }: any) => id === currentUserId);
    setUserFormOpen(true);
    setUser(currentUser);
  };

  const columns: GridColDef[] = [
    {
      headerName: 'Username',
      field: 'username',
      width: 200
    },
    {
      headerName: 'Projects',
      field: 'projects',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams): string =>
        (params.value as object[])?.map((p: any): string => p.name).join(', ')
    },
    {
      headerName: 'Role',
      field: 'role',
      width: 100
    }
  ];

  useEffect(() => {
    if (!currentUser.isAdmin) {
      setRedirect('/');
    }
    loadUsers();
  }, [currentUser.isAdmin]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Layout>
      <Content>
        <s.div.header>
          <s.h1>{t('users')}</s.h1>
          <CreateButton onClick={createUser} text={'New User'} />
        </s.div.header>
        <Grid
          name={users}
          columns={columns}
          rows={users}
          loading={loading}
          searchFields={['username', 'role']}
          onRowSelected={(selectionModel: GridSelectionModel): void => {
            editUser(selectionModel[0]);
          }}
        />
      </Content>
      <Sidebar visible={userFormOpen}>
        <UserForm user={user} setVisible={setUserFormOpen} onRefresh={loadUsers} />
      </Sidebar>
    </Layout>
  );
};

const s = {
  h1: styled.h1`
    font-size: 14px;
    line-height: 30px;
    margin: 0px;
    padding: 5px 10px 5px 0px;
    text-transform: uppercase;
  `,
  div: {
    header: styled.div`
      display: flex;
      height: 40px;
      width: 100%;
    `
  },
  Icon: styled(Icon)`
    position: absolute;
    right: 0px;
    top: 0px;
  `
};
