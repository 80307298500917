import axios from 'axios';
import { Button, Buttons, Checkbox, Field, Fields, Form, Heading, Icon, Input, Label, Modal } from 'components';
import { t } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { UserContext } from 'UserContext';

export const UserForm: React.FC<{
  onRefresh(): void;
  setVisible(status: boolean): void;
  user: any;
}> = ({ onRefresh, setVisible, user }) => {
  const { user: currentUser } = useContext(UserContext);
  const { register, handleSubmit, reset } = useForm();
  const [projects, setProjects] = useState<any>([]);
  const [warningOpen, setWarningOpen] = useState(false);

  const loadProjects = (): void => {
    axios.get(`/api/projects/`).then(response => setProjects(response.data));
  };
  const closeForm = (): void => {
    setVisible(false);
  };
  const openWarningModal = (): void => {
    setWarningOpen(true);
  };
  const closeWarningModal = (): void => {
    setWarningOpen(false);
  };
  const create = ({ username, password, role, projectIds }: any): void => {
    const newUser = {
      username,
      password,
      role,
      projectIds
    };

    axios.post(`/api/users/`, newUser).then(() => {
      closeForm();
      onRefresh();
    });
  };
  const update = ({ username, password, role, projectIds }: any): void => {
    const updatedUser = {
      id: user.id,
      username,
      password,
      role,
      projectIds
    };

    axios.put(`/api/users/`, updatedUser).then(() => {
      closeForm();
      onRefresh();
      // TODO: indicate completion
    });
  };
  const destroy = (): void => {
    closeWarningModal();

    axios.delete(`/api/users/`, { data: { id: user.id } }).then(() => {
      closeForm();
      onRefresh();
    });
  };

  useEffect(() => {
    reset();
  }, [reset, user]);

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <Form>
      <s.Icon icon={Icon.icons.x} onClick={closeForm} />
      <Heading>{user?.id ? t('editUser') : t('newUser')}</Heading>
      <Fields>
        <Field>
          <Label htmlFor="username">{t('username')}</Label>
          <Input name="username" ref={register} defaultValue={user?.username} disabled={!currentUser.isAdmin} />
        </Field>
        <Field>
          <Label htmlFor="password">{t('password')}</Label>
          <Input name="password" type="password" ref={register} defaultValue={user?.password} />
        </Field>
        {currentUser.isAdmin && (
          <Field>
            <Label htmlFor="role">{t('Role')}</Label>
            <Input name="role" ref={register} defaultValue={user?.role} />
          </Field>
        )}
        <Field>
          <Label htmlFor="projectIds">{t('projects')}</Label>
          {projects.length > 0 ? (
            <div>
              {projects.map((project: any) => (
                <Checkbox
                  key={project?.id}
                  ref={register}
                  name={'projectIds'}
                  label={project?.name}
                  value={project.id}
                  defaultChecked={user?.projects.some((p: any) => p.id === project.id)}
                />
              ))}
            </div>
          ) : (
            <div>Loading</div>
          )}
        </Field>
      </Fields>
      <Buttons>
        <Button text={t('cancel')} onClick={closeForm} />
        {user?.id ? (
          <>
            <Button text={t('delete')} type={Button.types.destroy} onClick={openWarningModal} />
            <Button text={t('update')} type={Button.types.submit} onClick={handleSubmit(update)} />
          </>
        ) : (
          <Button text={t('create')} type={Button.types.submit} onClick={handleSubmit(create)} />
        )}
      </Buttons>
      <Modal visible={warningOpen} setVisible={setWarningOpen}>
        <Heading>{t('areYouSure')}</Heading>
        <Buttons>
          <Button text={t('cancel')} type={Button.types.default} onClick={closeWarningModal} />
          <Button text={t('delete')} type={Button.types.destroy} onClick={destroy} />
        </Buttons>
      </Modal>
    </Form>
  );
};

const s = {
  Icon: styled(Icon)`
    position: absolute;
    top: 0px;
    right: 0px;

    &::after {
      font-size: 22px;
      line-height: 40px;
      height: 40px;
      width: 40px;
    }
  `
};
