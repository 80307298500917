import { Icon, Icons } from 'components/Icon';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const CreateButton: React.FC<{ text: string; onClick(): void }> = ({ text, onClick }) => (
  <s.div onClick={onClick} icon={Icon.icons.plus}>
    {text}
  </s.div>
);

const s = {
  Icon: styled(Icon)`
    margin: 4px 4px 4px 0;

    &:hover {
      background-color: ${colors.grey2};
    }
    &::after {
      font-size: 16px;
      font-weight: bold;
      height: 24px;
      line-height: 24px;
      text-align: center;
      width: 24px;
    }
  `,
  div: styled.div<{ icon: Icons }>`
    background-color: ${colors.grey1};
    border-radius: 3px;
    font-size: 12px;
    line-height: 20px;
    margin: 10px 10px 10px 0px;
    padding: 0px 4px 0px 8px;
    text-transform: uppercase;

    &:hover {
      background-color: ${colors.blue2};
      color: ${colors.white};
      cursor: pointer;
    }
    &::after {
      content: '${({ icon }): Icons => icon}';
      display: inline-block;
      font-family: icons;
      font-size: 14px;
      font-weight: 700;
      height: 16px;
      line-height: 20px;
      margin-left: 4px;
      text-align: center;
      width: 16px;
    }

    @media print {
      display: none;
    }
  `
};
