/* eslint-disable react/display-name */
import { GridCellValue, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Status } from 'components/terms/Status';
import dayjs from 'dayjs';
import React from 'react';
import { Project } from 'types';

export const getColumns = (project: Project | undefined): any => {
  if (project === undefined) {
    return [];
  }

  const fields = [project.field1, project.field2, project.field3, project.field4].filter(
    field => field && field !== ''
  );

  const columns: GridColDef[] = fields.map((field, index) => ({
    headerName: field,
    field: `field${index + 1}`,
    flex: 1
  }));

  const status: GridColDef = {
    field: 'status',
    headerName: ' ',
    resizable: false,
    width: 40,
    renderCell: (params: GridValueFormatterParams) => <Status color={String(params.value) || ''} />
  };

  const dates: GridColDef[] = [
    {
      headerName: 'Created',
      field: 'createdAt',
      width: 140,
      valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
        dayjs(String(params.value)).format('YY/MM/DD HH:mm')
    },
    {
      headerName: 'Updated',
      field: 'updatedAt',
      width: 140,
      valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
        dayjs(String(params.value)).format('YY/MM/DD HH:mm')
    }
  ];

  return [status, ...columns, ...dates];
};
