import { t } from 'i18n';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const Search: React.FC<{ onChange: (value: string) => void }> = ({ onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  return <s.input placeholder={t('search')} onChange={handleChange} type="search" />;
};

const s = {
  input: styled.input`
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.grey2};
    height: 30px;
    margin-bottom: 10px;
    padding: 0px 8px;
    width: 100%;

    @media print {
      display: none;
    }
  `
};
