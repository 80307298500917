import Fuse from 'fuse.js';
import { useMemo } from 'react';

const SYMBOLS = /[.*+?^${}()|[\]\\]/g;
const JA_PUNCTUATION = /[\u3000-\u303f]/g;
const WHITE_SPACE = /\s+/g;

function useSearch<T>(rows: T[], searchTerm: string, searchFields: string[]): any[] {
  return useMemo(() => {
    const fuse = new Fuse(rows, { minMatchCharLength: 2, threshold: 0.2, keys: searchFields });

    const escaped = searchTerm
      .replace(SYMBOLS, '')
      .replace(JA_PUNCTUATION, '')
      .replace(WHITE_SPACE, ' ');

    if (escaped === '') {
      return rows;
    }

    return fuse.search(escaped).map(({ item }) => item);
  }, [rows, searchFields, searchTerm]);
}

export { useSearch };
