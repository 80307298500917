import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const Input = React.forwardRef(
  (
    {
      name,
      type = 'text',
      defaultValue = '',
      disabled = false
    }: { name: string; type?: string; defaultValue?: string; disabled?: boolean },
    ref?: React.Ref<HTMLInputElement>
  ) => <s.input ref={ref} id={name} type={type} name={name} defaultValue={defaultValue} disabled={disabled} />
);

Input.displayName = 'Input';

const s = {
  input: styled.input`
    border-color: ${colors.grey1};
    border-style: solid;
    border-width: 1px;
    display: block;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
    width: 100%;

    &:hover {
      border-color: ${colors.grey2};
    }
    &:disabled {
      border-color: ${colors.grey1};
    }
    &:focus {
      border-color: ${colors.grey3};
      outline: none;
    }
  `
};
