import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { colors, fontFamily } from 'theme';

export const Textarea = React.forwardRef(
  (
    {
      name,
      defaultValue = ''
    }: {
      name: string;
      defaultValue?: string;
    },
    ref?: React.Ref<TextareaAutosize>
  ) => {
    return <s.TextareaAutosize ref={ref} id={name} name={name} defaultValue={defaultValue} />;
  }
);

Textarea.displayName = 'Textarea';

const s = {
  TextareaAutosize: styled(TextareaAutosize)`
    border-color: ${colors.grey2};
    border-style: solid;
    border-width: 1px;
    display: block;
    font-family: ${fontFamily};
    font-size: 14px;
    line-height: 20px;
    padding: 4px;
    resize: none;
    transition: border-color 0.3s;
    width: 100%;

    &:hover {
      transition: border-color 0s;
      border-color: ${colors.grey3};
    }
    &:focus {
      border-color: ${colors.grey4};
      outline: none;
      transition: border-color 0s;
    }
  `
};
