import axios from 'axios';
import { Field, Input, Label, Submit } from 'components';
import { Logo } from 'components/Logo';
import { t } from 'i18n';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'theme';
import { UserContext } from 'UserContext';

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { saveUser } = useContext(UserContext);
  const { register, errors, handleSubmit } = useForm({
    submitFocusError: true
  });
  const [redirect, setRedirect] = useState('');
  const [error, setError] = useState('');
  const username = createRef() as React.MutableRefObject<HTMLInputElement | null>;

  const onSubmit = ({ username, password }: any): void => {
    setIsLoading(true);

    axios
      .post(`/api/login`, { username, password })
      .then(response => {
        setIsLoading(false);
        saveUser(response.data);
        setRedirect('/');
      })
      .catch((): void => {
        setIsLoading(false);
        setError('Failed');
      });
  };

  useEffect(() => {
    username.current?.focus();
  }, [username]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <s.div.container>
      <s.form onSubmit={handleSubmit(onSubmit)}>
        <s.Logo width={180} height={50} />
        <Field>
          <Label htmlFor="username">{t('username')}:</Label>
          <Input
            type="text"
            name="username"
            ref={(element): void => {
              if (element) {
                register(element, { required: true });
                username.current = element;
              }
            }}
          />
          {errors.username && <s.div.error>{t('error.usernameRequired')}</s.div.error>}
        </Field>
        <Field>
          <Label htmlFor="password">{t('password')}:</Label>
          <Input type="password" name="password" ref={register({ required: true })} />
          {errors.password && <s.div.error>{t('error.passwordRequired')}</s.div.error>}
        </Field>
        <Submit text={t('signIn')} />
        <s.div.loading>{isLoading && t('loading')}</s.div.loading>
        {error && <s.div.error>{t('error.loginFailed')}</s.div.error>}
      </s.form>
    </s.div.container>
  );
};

const s = {
  Logo: styled(Logo)`
    margin-bottom: 20px;
  `,
  div: {
    container: styled.div`
      background-color: ${colors.grey1};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    `,
    loading: styled.div`
      color: ${colors.black};
      font-size: 12px;
      line-height: 16px;
      min-height: 20px;
      width: 100%;
    `,
    error: styled.div`
      color: ${colors.red};
      font-size: 12px;
      line-height: 16px;
      padding: 4px 4px;
    `
  },
  form: styled.form`
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
    padding: 24px;
  `
};
