import React from 'react';
import styled from 'styled-components';

export const Sidebar: React.FC<{ visible?: boolean; children: React.ReactNode }> = ({ visible = false, children }) => {
  if (!visible) {
    return null;
  }

  return <s.div>{children}</s.div>;
};

const s = {
  div: styled.div`
    width: 300px;

    @media print {
      display: none;
    }
  `
};
