import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export const Form: React.FC<{ className?: string; children: React.ReactNode }> = ({ className, children }) => (
  <s.form className={className}>{children}</s.form>
);

const s = {
  form: styled.form`
    background-color: ${colors.white};
    border-left: 1px ${colors.grey2} solid;
    flex: 1;
    min-height: 100%;
    max-width: 600px;
    padding: 0px 10px 10px;
    position: relative;
  `
};
