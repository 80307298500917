import axios from 'axios';
import { Content, CreateButton, Icon, Layout, Sidebar } from 'components';
import { ProjectForm } from 'components/projects/ProjectForm';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { t } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'theme';
import { Project } from 'types';
import { UserContext } from 'UserContext';

dayjs.extend(relativeTime);

export const Projects: React.FC = () => {
  const { user } = useContext(UserContext);

  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<any>([]);
  const [redirect, setRedirect] = useState('');
  const [projectFormOpen, setProjectFormOpen] = useState(false);

  const loadProjects = (): void => {
    axios
      .get(`/api/projects/`)
      .then(response => setProjects(response.data))
      // TODO: show error instead of redirect to login.
      .catch(() => {
        setRedirect('/logout');
      });
  };

  const createProject = (): void => {
    setProjectFormOpen(true);
    setProject(undefined);
  };

  const editProject = (currentProject: any, event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
    setProjectFormOpen(true);
    setProject(currentProject);
  };

  useEffect(() => {
    loadProjects();
  }, []);

  if (projects.length === 1) {
    return <Redirect to={`/glossary/${projects[0].id}`} />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Layout>
      <Content>
        <s.div.header>{user.isAdmin && <CreateButton onClick={createProject} text={'New Glossary'} />}</s.div.header>
        <s.div.projects>
          {projects.map((project: Project) => (
            <s.Link key={project.id} to={`/glossary/${project.id}`}>
              <s.h2>{project.name}</s.h2>
              <s.h3>
                {t('Total:')} {project.total}
              </s.h3>
              {project.lastUpdated && <s.h3>Updated: {dayjs(project.lastUpdated).fromNow()}</s.h3>}
              {user.isAdmin && (
                <s.Icon
                  icon={Icon.icons.edit}
                  onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => editProject(project, event)}
                />
              )}
            </s.Link>
          ))}
        </s.div.projects>
      </Content>
      <Sidebar visible={projectFormOpen}>
        <ProjectForm setVisible={setProjectFormOpen} project={project} onRefresh={loadProjects} />
      </Sidebar>
    </Layout>
  );
};

const s = {
  h1: styled.h1`
    font-size: 14px;
    line-height: 30px;
    margin: 0px;
    padding: 5px 10px 5px 0px;
    text-transform: uppercase;
  `,
  h2: styled.h2`
    margin: 0px;
    font-size: 20px;
    font-weight: normal;
    flex: 1;
  `,
  h3: styled.h3`
    color: ${colors.grey3};
    margin: 0px;
    font-weight: normal;
    font-size: 12px;
  `,
  Link: styled(Link)`
    border-radius: 3px;
    border-color: ${colors.grey2};
    border-style: solid;
    border-width: 1px;
    color: ${colors.grey5};
    display: flex;
    flex-direction: column;
    height: 100px;
    margin: 5px;
    padding: 10px;
    position: relative;
    text-decoration: none;
    width: calc(25% - 10px);

    &:hover {
      background-color: ${colors.blue5};
      border-color: ${colors.blue5};
    }
  `,
  div: {
    header: styled.div`
      display: flex;
      height: 40px;
      width: 100%;
    `,
    projects: styled.div`
      align-content: flex-start;
      display: flex;
      margin: -5px;
      flex: 1;
      flex-wrap: wrap;
    `
  },
  Icon: styled(Icon)`
    border-radius: 50%;
    bottom: 4px;
    position: absolute;
    right: 4px;

    &::after {
      height: 30px;
      line-height: 30px;
      width: 30px;
    }

    &:hover {
      background-color: ${colors.blue2};
      color: ${colors.white};
    }
  `
};
