export const font = 'System';

export const fontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
'Droid Sans', 'Helvetica Neue', sans-serif`;

// prettier-ignore
export const colors = {
  black:      '#000000',
  blue1:      '#40a3ff',
  blue2:      '#0085ff',
  blue3:      '#0056a5',
  blue4:      '#E9EBEF',
  blue5:      '#c3cad8',
  green:      '#219653',
  green2:     '#92DB84',
  green3:     '#b6efb6',
  grey1:      '#efefef',
  grey2:      '#cccccc',
  grey3:      '#6a6a6a',
  grey4:      '#333333',
  grey5:      '#272c34',
  red:        '#eb5757',
  red2:       '#EA7268',
  red3:       '#ffc0c0',
  orange:     '#d86c37',
  yellow:     '#ffe17e',
  transparent: 'transparent',
  white:      '#ffffff'
};

export const getStatusColor = (status: string): string => {
  switch (status) {
    case 'RED':
      return colors.red2;
    case 'YELLOW':
      return colors.yellow;
    default:
      return colors.green2;
  }
};
