import { DataGridPro, GridRowModel, GridSelectionModel } from '@mui/x-data-grid-pro';
import { Icon } from 'components';
import { useSearch } from 'hooks/useSearch';
import { t } from 'i18n';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, fontFamily } from 'theme';
import XLSX from 'xlsx';

import { Search } from './Search';

export const Grid: React.FC<{
  name: string;
  columns: any;
  rows: GridRowModel[];
  loading?: boolean;
  searchFields?: string[];
  canSearch?: boolean;
  canDownload?: boolean;
  onRowSelected?: (selectionModel: GridSelectionModel) => void;
}> = ({
  name,
  rows,
  columns,
  loading = false,
  canSearch = true,
  canDownload = true,
  onRowSelected,
  searchFields = []
}) => {
  const [filter, setFilter] = useState('');
  const filteredRows = useSearch(rows, filter, searchFields);

  const handleChange = (value: string): void => {
    setFilter(value);
  };

  /**
   * Download Excel
   */
  const handleDownloadExcel = (): void => {
    if (columns.length !== 0) {
      const time = new Date().toISOString().split('.')[0];
      const workBook = XLSX.utils.book_new();
      const formattedTerms = rows.map((term: any) => {
        const dataFields = columns
          .filter((column: any) => column.field.startsWith('field'))
          .map((column: any) => column.headerName)
          .reduce((acc: { [key: string]: string }, name: string, index: number) => {
            acc[name] = term[`field${index + 1}`];
            return acc;
          }, {});

        return {
          Status: term.status,
          ...dataFields,
          'Created At': term.createdAt,
          'Updated At': term.updatedAt,
          'Created By': term.createdBy,
          'Updated By': term.updatedBy
        };
      });

      XLSX.utils.book_append_sheet(workBook, XLSX.utils.json_to_sheet(formattedTerms), `Sheet1`);
      XLSX.writeFile(workBook, `Glossary - ${name} - ${time}.xlsx`);
    }
  };

  function Footer(): JSX.Element {
    return (
      <s.div.toolbar>
        {canDownload && (
          <s.div.download onClick={handleDownloadExcel}>
            <s.Icon icon={Icon.icons.download} />
            Download Excel
          </s.div.download>
        )}
        <s.div.total>
          {t('Total')}: {filteredRows.length}
        </s.div.total>
      </s.div.toolbar>
    );
  }

  return (
    <s.div.container>
      {canSearch && <Search onChange={handleChange} />}
      <DataGridPro
        columns={columns}
        components={{ Footer }}
        density="compact"
        disableColumnFilter={true}
        headerHeight={40}
        loading={loading}
        onSelectionModelChange={onRowSelected}
        rowHeight={40}
        rows={filteredRows}
      />
    </s.div.container>
  );
};

const s = {
  div: {
    container: styled.div`
      flex: 1;
      font-family: ${fontFamily};
      height: calc(100% - 80px);

      .MuiDataGrid-root {
        border-radius: 0px;
        border-color: ${colors.grey2};
        font-family: ${fontFamily};
        .MuiDataGrid-row {
          @media print {
            max-height: 100% !important;
          }
        }
        .MuiDataGrid-cell {
          @media print {
            font-size: 24px;
            line-height: 34px !important;
            max-height: 100% !important;
            overflow: auto;
            text-overflow: inherit;
            white-space: normal;
          }
        }
        .MuiDataGrid-cell:focus {
          outline: none;
        }
        .MuiDataGrid-colCellTitle {
          font-weight: normal;
        }
        .MuiDataGrid-row {
          &:hover {
            background-color: ${colors.grey1};
          }

          &.Mui-selected {
            background-color: ${colors.grey1};
            &:hover {
              background-color: ${colors.grey1};
            }
          }
        }
      }
      .MuiButton-textPrimary {
        color: ${colors.black};
      }
      .MuiButton-textSizeSmall {
        padding: 2px;
      }
      .MuiButton-label {
        border-radius: 3px;
        font-family: ${fontFamily};
        font-size: 13px;
        font-weight: normal;
        text-transform: none;
        padding: 0px 4px;
      }
    `,
    toolbar: styled.div`
      border-color: ${colors.grey2};
      border-style: solid;
      border-width: 1px 0px 0px;
      display: flex;
      padding: 2px 4px;

      @media print {
        display: none;
      }
    `,
    total: styled.div`
      flex: 1;
      font-size: 14px;
      line-height: 26px;
      text-align: right;
    `,
    download: styled.div`
      line-height: 26px;
      &:hover {
        color: ${colors.blue2};
        cursor: pointer;
      }
    `
  },
  input: styled.input`
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.grey2};
    height: 30px;
    margin-bottom: 10px;
    padding: 0px 8px;
    width: 100%;

    @media print {
      display: none;
    }
  `,
  Icon: styled(Icon)`
    display: inline-block;
    margin-right: 6px;
  `
};
