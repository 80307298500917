import { Icon } from 'components';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { colors } from 'theme';

export const Modal: React.FC<{
  visible?: boolean;
  setVisible(status: boolean): void;
  children: React.ReactNode;
}> = ({ visible = false, setVisible, children }) => {
  const customStyles = {
    content: {
      border: 'none',
      borderRadius: '0px',
      bottom: 'auto',
      left: '50%',
      marginRight: '-50%',
      padding: '0px 20px 20px',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.3)',
      overflow: 'scroll',
      zIndex: 1000
    }
  };

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  return (
    <ReactModal isOpen={visible} style={customStyles} onRequestClose={(): void => setVisible(false)}>
      <s.Icon icon={Icon.icons.x} onClick={(): void => setVisible(false)} />
      {children}
    </ReactModal>
  );
};

const s = {
  Icon: styled(Icon)`
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;

    &::after {
      font-size: 24px;
      height: 40px;
      line-height: 40px;
      width: 40px;
    }
    &:hover {
      background-color: ${colors.grey1};

      &::after {
        color: ${colors.blue2};
      }
    }
  `
};
