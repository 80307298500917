import React from 'react';
import styled from 'styled-components';

export const Fields: React.FC<{ className?: string; children: React.ReactNode }> = ({ className, children }) => (
  <s.div className={className}>{children}</s.div>
);

const s = {
  div: styled.div``
};
