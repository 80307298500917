/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export const UserContext = createContext<{
  user: any;
  saveUser: (user: any) => void;
  deleteUser: () => void;
}>({
  user: null,
  saveUser: () => {},
  deleteUser: () => {}
});
