/* eslint-disable @typescript-eslint/camelcase */

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'theme';

export enum Icons {
  activity = '\f101',
  airplay = '\f102',
  alertcircle = '\f103',
  alertoctagon = '\f104',
  alerttriangle = '\f105',
  aligncenter = '\f106',
  alignjustify = '\f107',
  alignleft = '\f108',
  alignright = '\f109',
  anchor = '\f10a',
  aperture = '\f10b',
  archive = '\f10c',
  arrowdowncircle = '\f10d',
  arrowdownleft = '\f10e',
  arrowdownright = '\f10f',
  arrowdown = '\f110',
  arrowleftcircle = '\f111',
  arrowleft = '\f112',
  arrowrightcircle = '\f113',
  arrowright = '\f114',
  arrowupcircle = '\f115',
  arrowupleft = '\f116',
  arrowupright = '\f117',
  arrowup = '\f118',
  atsign = '\f119',
  award = '\f11a',
  barchart2 = '\f11b',
  barchart = '\f11c',
  batterycharging = '\f11d',
  battery = '\f11e',
  belloff = '\f11f',
  bell = '\f120',
  bluetooth = '\f121',
  bold = '\f122',
  bookopen = '\f123',
  book = '\f124',
  bookmark = '\f125',
  box = '\f126',
  briefcase = '\f127',
  calendar = '\f128',
  cameraoff = '\f129',
  camera = '\f12a',
  cast = '\f12b',
  checkcircle = '\f12c',
  checksquare = '\f12d',
  check = '\f12e',
  chevrondown = '\f12f',
  chevronleft = '\f130',
  chevronright = '\f131',
  chevronup = '\f132',
  chevronsdown = '\f133',
  chevronsleft = '\f134',
  chevronsright = '\f135',
  chevronsup = '\f136',
  chrome = '\f137',
  circle = '\f138',
  clipboard = '\f139',
  clock = '\f13a',
  clouddrizzle = '\f13b',
  cloudlightning = '\f13c',
  cloudoff = '\f13d',
  cloudrain = '\f13e',
  cloudsnow = '\f13f',
  cloud = '\f140',
  code = '\f141',
  codepen = '\f142',
  codesandbox = '\f143',
  coffee = '\f144',
  columns = '\f145',
  command = '\f146',
  compass = '\f147',
  copy = '\f148',
  cornerdownleft = '\f149',
  cornerdownright = '\f14a',
  cornerleftdown = '\f14b',
  cornerleftup = '\f14c',
  cornerrightdown = '\f14d',
  cornerrightup = '\f14e',
  cornerupleft = '\f14f',
  cornerupright = '\f150',
  cpu = '\f151',
  creditcard = '\f152',
  crop = '\f153',
  crosshair = '\f154',
  database = '\f155',
  delete = '\f156',
  disc = '\f157',
  dollarsign = '\f158',
  downloadcloud = '\f159',
  download = '\f15a',
  droplet = '\f15b',
  edit2 = '\f15c',
  edit3 = '\f15d',
  edit = '\f15e',
  externallink = '\f15f',
  eyeoff = '\f160',
  eye = '\f161',
  facebook = '\f162',
  fastforward = '\f163',
  feather = '\f164',
  figma = '\f165',
  fileminus = '\f166',
  fileplus = '\f167',
  filetext = '\f168',
  file = '\f169',
  film = '\f16a',
  filter = '\f16b',
  flag = '\f16c',
  folderminus = '\f16d',
  folderplus = '\f16e',
  folder = '\f16f',
  framer = '\f170',
  frown = '\f171',
  gift = '\f172',
  gitbranch = '\f173',
  gitcommit = '\f174',
  gitmerge = '\f175',
  gitpullrequest = '\f176',
  github = '\f177',
  gitlab = '\f178',
  globe = '\f179',
  grid = '\f17a',
  harddrive = '\f17b',
  hash = '\f17c',
  headphones = '\f17d',
  heart = '\f17e',
  helpcircle = '\f17f',
  hexagon = '\f180',
  home = '\f181',
  image = '\f182',
  inbox = '\f183',
  info = '\f184',
  instagram = '\f185',
  italic = '\f186',
  key = '\f187',
  layers = '\f188',
  layout = '\f189',
  lifebuoy = '\f18a',
  link2 = '\f18b',
  link = '\f18c',
  linkedin = '\f18d',
  list = '\f18e',
  loader = '\f18f',
  lock = '\f190',
  login = '\f191',
  logout = '\f192',
  mail = '\f193',
  mappin = '\f194',
  map = '\f195',
  maximize2 = '\f196',
  maximize = '\f197',
  meh = '\f198',
  menu = '\f199',
  messagecircle = '\f19a',
  messagesquare = '\f19b',
  micoff = '\f19c',
  mic = '\f19d',
  minimize2 = '\f19e',
  minimize = '\f19f',
  minuscircle = '\f1a0',
  minussquare = '\f1a1',
  minus = '\f1a2',
  monitor = '\f1a3',
  moon = '\f1a4',
  morehorizontal = '\f1a5',
  morevertical = '\f1a6',
  mousepointer = '\f1a7',
  move = '\f1a8',
  music = '\f1a9',
  navigation2 = '\f1aa',
  navigation = '\f1ab',
  octagon = '\f1ac',
  package = '\f1ad',
  paperclip = '\f1ae',
  pausecircle = '\f1af',
  pause = '\f1b0',
  pentool = '\f1b1',
  percent = '\f1b2',
  phonecall = '\f1b3',
  phoneforwarded = '\f1b4',
  phoneincoming = '\f1b5',
  phonemissed = '\f1b6',
  phoneoff = '\f1b7',
  phoneoutgoing = '\f1b8',
  phone = '\f1b9',
  piechart = '\f1ba',
  playcircle = '\f1bb',
  play = '\f1bc',
  pluscircle = '\f1bd',
  plussquare = '\f1be',
  plus = '\f1bf',
  pocket = '\f1c0',
  power = '\f1c1',
  printer = '\f1c2',
  radio = '\f1c3',
  refreshccw = '\f1c4',
  refreshcw = '\f1c5',
  repeat = '\f1c6',
  rewind = '\f1c7',
  rotateccw = '\f1c8',
  rotatecw = '\f1c9',
  rss = '\f1ca',
  save = '\f1cb',
  scissors = '\f1cc',
  search = '\f1cd',
  send = '\f1ce',
  server = '\f1cf',
  settings = '\f1d0',
  share2 = '\f1d1',
  share = '\f1d2',
  shieldoff = '\f1d3',
  shield = '\f1d4',
  shoppingbag = '\f1d5',
  shoppingcart = '\f1d6',
  shuffle = '\f1d7',
  sidebar = '\f1d8',
  skipback = '\f1d9',
  skipforward = '\f1da',
  slack = '\f1db',
  slash = '\f1dc',
  sliders = '\f1dd',
  smartphone = '\f1de',
  smile = '\f1df',
  speaker = '\f1e0',
  square = '\f1e1',
  stars = '\f1e2',
  star = '\f1e3',
  stopcircle = '\f1e4',
  sun = '\f1e5',
  sunrise = '\f1e6',
  sunset = '\f1e7',
  tag = '\f1e8',
  target = '\f1e9',
  terminal = '\f1ea',
  thermometer = '\f1eb',
  thumbsdown = '\f1ec',
  thumbsup = '\f1ed',
  toggleleft = '\f1ee',
  toggleright = '\f1ef',
  trash2 = '\f1f0',
  trash = '\f1f1',
  trello = '\f1f2',
  trendingdown = '\f1f3',
  trendingup = '\f1f4',
  triangle = '\f1f5',
  truck = '\f1f6',
  tv = '\f1f7',
  twitter = '\f1f8',
  type = '\f1f9',
  umbrella = '\f1fa',
  underline = '\f1fb',
  unlock = '\f1fc',
  uploadcloud = '\f1fd',
  upload = '\f1fe',
  usercheck = '\f1ff',
  userminus = '\f200',
  userplus = '\f201',
  userx = '\f202',
  user = '\f203',
  users = '\f204',
  videooff = '\f205',
  video = '\f206',
  voicemail = '\f207',
  volume1 = '\f208',
  volume2 = '\f209',
  volumex = '\f20a',
  volume = '\f20b',
  watch = '\f20c',
  wifioff = '\f20d',
  wifi = '\f20e',
  wind = '\f20f',
  xcircle = '\f210',
  xoctagon = '\f211',
  xsquare = '\f212',
  x = '\f213',
  youtube = '\f214',
  zapoff = '\f215',
  zap = '\f216',
  zoomin = '\f217',
  zoomout = '\f218'
}

export const Icon: React.FC<{
  children?: React.ReactNode;
  className?: string;
  icon: Icons;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  to?: string;
}> & {
  icons: typeof Icons;
} = ({ children, className, icon, onClick, to }) => {
  if (to) {
    return (
      <s.Link to={to} className={className} icon={icon}>
        {children}
      </s.Link>
    );
  } else if (onClick) {
    return (
      <s.div onClick={onClick} className={className} icon={icon}>
        {children}
      </s.div>
    );
  }

  return (
    <s.div className={className} icon={icon}>
      {children}
    </s.div>
  );
};

const s = {
  div: styled.div<{ icon: Icons }>`
    &:hover {
      color: ${colors.blue2};
      cursor: pointer;
    }
    &::after {
      content: '${({ icon }): Icons => icon}';
      font-family: icons;
      font-size: 14px;
      display: block;
      text-align: center;
      line-height: 16px;
      height: 16px;
      width: 16px;
    }
  `,
  Link: styled(Link)<{ icon: Icons }>`
    &::after {
      content: '${({ icon }): Icons => icon}';
      font-family: icons;
      font-size: 14px;
      display: block;
      text-align: center;
      line-height: 16px;
      height: 16px;
      width: 16px;
    }
  `
};

Icon.icons = Icons;
