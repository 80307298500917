import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useUser } from 'hooks/useUser';
import { NotificationsContext } from 'NotificationsContext';
import { All, Login, Logout, Project, Projects, Settings, Users } from 'pages';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { fontFamily } from 'theme';
import { UserContext } from 'UserContext';

// XGrid License
LicenseInfo.setLicenseKey(
  '9bf8d592c7703e7f9185d7ebcb0826dbT1JERVI6MjEzNDMsRVhQSVJZPTE2NDQ4OTAwMzEwMDAsS0VZVkVSU0lPTj0x'
);

export const App: React.FC = () => {
  const { user, saveUser, deleteUser } = useUser();
  const notification = React.createRef<NotificationSystem.System>();

  return (
    <UserContext.Provider value={{ user, saveUser, deleteUser }}>
      <NotificationsContext.Provider value={{ notification }}>
        <BrowserRouter>
          <s.NotificationSystem ref={notification} />
          <Switch>
            <Route path="/login" component={Login} />
            {!user && <Redirect to="/login" />}
            <Route path="/logout" component={Logout} />
            <Route path="/settings" component={Settings} />
            <Route path="/users" component={Users} />
            <Route path="/glossary/:id" component={Project} />
            <Route path="/all" component={All} />
            <Route path="/" component={Projects} />
          </Switch>
        </BrowserRouter>
      </NotificationsContext.Provider>
    </UserContext.Provider>
  );
};

css`
  * {
    box-sizing: border-box;
  }
  html {
    height: 100vh;
  }
  body {
    margin: 0;
    font-family: ${fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
  }
`;

const s = {
  NotificationSystem: styled(NotificationSystem).attrs({
    style: {
      Containers: {
        DefaultStyle: {
          padding: 0
        },
        tr: {
          top: 40,
          right: 16
        }
      },
      NotificationItem: {
        DefaultStyle: {
          border: 'none',
          boxShadow: 'none',
          marginBottom: 8,
          padding: 16
        }
      }
    }
  })``
};

export default App;
