import axios from 'axios';
import { Button, Buttons, Field, Fields, Form, Heading, Icon, Input, Label, Modal } from 'components';
import { useNotification } from 'hooks/useNotification';
import { t } from 'i18n';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Project } from 'types';

export const ProjectForm: React.FC<{
  onRefresh(): void;
  setVisible(status: boolean): any;
  project?: Project;
}> = ({ onRefresh, setVisible, project }) => {
  const { register, handleSubmit, reset } = useForm<Project>();
  const [warningOpen, setWarningOpen] = useState(false);
  const { success } = useNotification();

  const closeForm = (): void => {
    setVisible(false);
  };
  const openWarningModal = (): void => {
    setWarningOpen(true);
  };
  const closeWarningModal = (): void => {
    setWarningOpen(false);
  };

  const create = ({ name, field1, field2, field3, field4 }: any): void => {
    const newProject = { name, field1, field2, field3, field4 };

    axios.post(`/api/projects/`, newProject).then(() => {
      closeForm();
      onRefresh();
      success(t('projectCreated'));
    });
  };
  const update = ({ name, field1, field2, field3, field4 }: any): void => {
    const updatedProject = { id: project?.id, name, field1, field2, field3, field4 };

    axios.put(`/api/projects/`, updatedProject).then(() => {
      closeForm();
      onRefresh();
      success(t('projectUpdated'));
    });
  };
  const destroy = (): void => {
    closeWarningModal();

    axios.delete(`/api/projects/`, { data: { id: project?.id } }).then(() => {
      closeForm();
      onRefresh();
      success(t('projectDeleted'));
    });
  };

  useEffect(() => {
    reset();
  }, [reset, project]);

  return (
    <Form>
      <s.Icon icon={Icon.icons.x} onClick={closeForm} />
      <Heading>{project?.id ? t('editProject') : t('newProject')}</Heading>
      <Fields>
        <Field>
          <Label htmlFor="name">{t('name')}</Label>
          <Input name="name" ref={register} defaultValue={project?.name} />
        </Field>
        <fieldset>
          <legend>{t('fields')}</legend>
          <Field>
            <Label htmlFor="field1">Column 1</Label>
            <Input name="field1" ref={register} defaultValue={project?.field1} />
          </Field>
          <Field>
            <Label htmlFor="field1">Column 2</Label>
            <Input name="field2" ref={register} defaultValue={project?.field2} />
          </Field>
          <Field>
            <Label htmlFor="field1">Column 3</Label>
            <Input name="field3" ref={register} defaultValue={project?.field3} />
          </Field>
          <Field>
            <Label htmlFor="field1">Column 4</Label>
            <Input name="field4" ref={register} defaultValue={project?.field4} />
          </Field>
        </fieldset>
      </Fields>

      <Buttons>
        <Button text={t('cancel')} onClick={closeForm} />
        {project?.id ? (
          <>
            <Button
              text={t('delete')}
              type={Button.types.destroy}
              onClick={openWarningModal}
              disabled={project?.total > 0}
            />
            <Button text={t('update')} type={Button.types.submit} onClick={handleSubmit(update)} />
          </>
        ) : (
          <Button text={t('create')} type={Button.types.submit} onClick={handleSubmit(create)} />
        )}
        <Modal visible={warningOpen} setVisible={setWarningOpen}>
          <Heading>{t('areYouSure')}</Heading>
          <Buttons>
            <Button text={t('cancel')} type={Button.types.default} onClick={closeWarningModal} />
            <Button text={t('delete')} type={Button.types.destroy} onClick={destroy} />
          </Buttons>
        </Modal>
      </Buttons>
    </Form>
  );
};

const s = {
  Icon: styled(Icon)`
    position: absolute;
    top: 0px;
    right: 0px;

    &::after {
      font-size: 22px;
      line-height: 40px;
      height: 40px;
      width: 40px;
    }
  `
};
