import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from 'UserContext';

export const Logout: React.FC = () => {
  const { deleteUser } = useContext(UserContext);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    deleteUser();
    axios.get(`/api/logout`).then((): void => {
      setRedirect('/logout');
    });
  }, [deleteUser]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return null;
};
