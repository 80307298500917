import React from 'react';
import styled from 'styled-components';

export const Label: React.FC<{ htmlFor?: string; children: React.ReactNode }> = ({ htmlFor = '', children }) => (
  <s.label htmlFor={htmlFor}>{children}</s.label>
);

const s = {
  label: styled.label`
    display: block;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
  `
};
